import {
  Box,
  Link,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import { PaginationItem } from "@mui/material";
// import Pagination from "@material-ui/lab/Pagination";
import React, {
  useEffect,
  useState,
} from "react";
// import {
//   borderRadius,
//   borderSize,
//   colors,
//   fontSize,
//   gaps,
//   iconSize,
//   padding,
// } from "theme/defaultStyle";

interface Props {
  totalRows: number;
  totalPages?: number;
  rowsPerPage: number;
  page: number;
  isBackToTopRequired?: Boolean;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (count: number) => void;
}

const StyledPaginationContainer = styled(Box)({
  display: "flex",
  flexFlow: "row nowrap",
  alignItems: 'center',
  gap: "30px",
});

const EntriesPerPage = styled("div")({
  alignItems: "center",
  display: "flex",
  gap: "8px",
  fontSize: "14px",
  "& span": {
    width: "119px",
  },
});
const EntriesSelect = styled("div")({
  alignContent: "center",
  borderRadius: "2px",
  display: "flex",
  height: '40px',
  "& .MuiInput-root": {
    display: "flex",
    gap: "8px",
    padding: "0px",
    "& .MuiSelect-select": {
      fontSize: "16px",
      lineHeight: "150%",
    },
    "& .MuiSelect-icon": {
      color: "#0075AA",
      fontSize: "24px",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff",
    },
  },
});

const StyledPagination = styled(Pagination)({
  marginLeft: 'auto',
  "& .MuiPaginationItem-ellipsis": {
    color: "#0075AA",
  },
});

const StyledPaginationItem = styled(PaginationItem)({
  "&.MuiPaginationItem-root": {
    color: "#0075AA",
    fontSize: "14px",
    height: "32px",
    '&.Mui-selected': {
      backgroundColor: "#0075AA",
      color: "#fff",
    },
  },
});

const StewartTableFooterPaginator = ({
  totalRows,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  totalPages = 0,
  isBackToTopRequired = false,
}: Props) => {
  const entriesPerPageOptions = [10, 20, 30, 50, 100];
  const [pageCount, setPageCount] = useState(0);
  const [startEntries, setStartEntries] = useState("");
  const [endEntries, setEndEntries] = useState("");
  const [totalEntries, setTotalEntries] = useState("");
  const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) =>
    onPageChange(page);

  useEffect(() => {
    let pages = 1;
    let nStart = 0;
    let nEnd = 0;
    let nTotal = 0;

    if (totalRows > 0 && rowsPerPage > 0) {
      pages = Math.ceil(totalRows / rowsPerPage);
      nTotal = totalRows;

      if (page > 0) {
        nStart = ((page - 1) * rowsPerPage) + 1;
        nEnd = Math.min((page * rowsPerPage), totalRows);
      }
    }

    setStartEntries(nStart.toLocaleString());
    setEndEntries(nEnd.toLocaleString());
    setTotalEntries(nTotal.toLocaleString());
    setPageCount(pages);
  }, [page, totalRows, rowsPerPage])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <StyledPaginationContainer>
      {/* <div> */}
      <EntriesPerPage>
        <EntriesSelect>
          <Select
            disableUnderline={true}
            onChange={(e: any) => onRowsPerPageChange(e.target.value as number)}
            value={rowsPerPage}
          >
            {entriesPerPageOptions.map((num) => (
              <MenuItem
                key={num}
                selected={num === rowsPerPage}
                value={num}
              >
                {num}
              </MenuItem>
            ))}
          </Select>
        </EntriesSelect>
        <Typography sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", letterSpacing: "0em", textAlign: "left" }}>entries per page</Typography>
      </EntriesPerPage>
      <Typography sx={{ fontSize: "14px", fontWeight: 400, lineHeight: "20px", letterSpacing: "0em", textAlign: "left" }}>Showing {startEntries} to {endEntries} of {totalEntries} entries</Typography>
      {/* </div> */}

      {
        isBackToTopRequired &&
        <Box sx={{ marginLeft: 'auto', paddingBottom: '0px', display: 'flex', justifyContent: 'center' }}>
          <Box>
            <ArrowUpwardIcon sx={{ fontSize: "20px", color: '#0075aa' }} />&nbsp;
          </Box>
          <Box>
            <Link onClick={scrollToTop} color={'#0075aa'} sx={{ textDecoration: "none", fontWeight: 700, fontSize: '15px', cursor: 'pointer' }}>Back To Top</Link>
          </Box>
        </Box>
      }

      <StyledPagination
        showFirstButton
        showLastButton
        boundaryCount={2}
        count={pageCount}
        onChange={handlePageChange}
        page={page}
        renderItem={(item: any) => <StyledPaginationItem {...item} />}
      />
    </StyledPaginationContainer>
  );
};

export default StewartTableFooterPaginator;
