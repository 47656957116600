import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export interface TabState {
    tabName: string;
    subTabName: string;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}

const initialState: TabState = {
    tabName: "",
    subTabName: "",
    status: 'idle',
};

interface TabIdentification {
    tabName: string
    subTabName?: string
}

export const setTabToState = createAsyncThunk(
    'Tab/setTabToState',
    async (tabIdentification: TabIdentification) => {
        return tabIdentification;
    }
);

export const resetTabState = createAction('REVERT_TAB_STATE');

export const tabIdentifierSlice = createSlice({
    name: 'TabIdentifier',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(setTabToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.tabName = action.payload.tabName
                state.subTabName = action.payload.subTabName ?? ""
            })
            .addCase(resetTabState, () => initialState);
    },
});

export const tabIdentifier = (state: RootState) => state.rootReducer.Tab;
export default tabIdentifierSlice.reducer;
