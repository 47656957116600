import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { apnList, resetAPNState, searchByAPN, setAPNToState } from './apnSearchSlice';
import { APNSearchForm } from '../../entities/Types';
import PropertyTable from '../../components/ui/propertyTable/PropertyTable';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import SearchForm from './searchForm/SearchForm';
import styles from './apnSearch.module.css';
import MatchCodes from '../../utils/enums/MatchCodes';
import {
    SINGLE_MATCH_TEXT,
    MULTIPLE_MATCH_TEXT,
    MULTIPLE_MATCH_RESULT_TEXT,
    NO_COVERAGE_TEXT, NO_COVERAGE_RESULT_TEXT,
    EXCEPTION_MATCH_TEXT,
    EXCEPTION_MATCH_RESULT_TEXT,
    DEFAULT_EMPTY_TEXT, INVALID_APN_TEXT, INVALID_APN_RESULT_TEXT, keyPageNo, APNSearchErrorMessages, NO_MATCH_FOUND,
    APNSearch_Logging_Event
} from '../../utils/constants/constants';
import { APN_TAB, APN_TAB_HEADER, POLICY_APN_TAB } from '../../utils/constants/tabConstants';
import { clearSessionStorageByKeyStartingWith, getLengthOfAstrik } from '../../utils/common/commonUtils';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import TabHeader from '../../components/ui/tabHeader/TabHeader';
import { ValidationText } from '../../components/StyledComponents/CommonControls';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import PolicyAPNsearch from '../policyAPNSearch/PolicyAPNsearch';
import { tabIdentifier } from '../../components/ui/propertyTable/tabIdentifierSlice';

export const errorMessages = {
    InvalidAPNError: "APN_Is_Invalid",
    DoubleAstrikError: "Double_Astrik_Error",
    MaxCharactersError: "Maximum_50_Characters",
    APNRequiredError: "APN_Is_Required"
}

function APNsearch() {

    const apnListState = useAppSelector(apnList);
    const dispatch = useAppDispatch();
    const [isLoading, setLoading] = useState(false);
    const [matchCode, setMatchCode] = useState<string>("");
    const [matchText, setMatchText] = useState<string>("");
    const [resultText, setResultText] = useState<string>("");
    const [searchedAPN, setSearchedAPN] = useState<APNSearchForm>();
    const [errorMessage, setErrorMessage] = useState("");

    const [isAssesementAPNSearch, setAssesementAPNSearch] = React.useState(true);
    const [isPolicyAPNSearch, setPolicyAPNSearch] = React.useState(false);
    const tabState = useAppSelector(tabIdentifier);

    async function handleSubmit(formData: APNSearchForm) {
        try {
            setMatchCode("");
            if (getLengthOfAstrik(formData.APNField) > 1) {
                dispatch(resetAPNState());
                setMatchCode(MatchCodes.INVALID_APN.toString());
                return;
            }
            setLoading(true);
            setSearchedAPN(formData);
            dispatch(searchByAPN(formData));
            dispatch(setAPNToState(formData));
            clearSessionStorageByKeyStartingWith(keyPageNo + APN_TAB);
        }
        catch (e) {
        }
    }

    async function handleClearSearch(formData: APNSearchForm) {
        // if (matchCode === MatchCodes.INVALID_APN.toString())
        setMatchCode("");
    }

    useEffect(() => {
        if (tabState && tabState.subTabName === POLICY_APN_TAB) {
            setTimeout(function () {
                setAssesementAPNSearch(false);
                setPolicyAPNSearch(true);
            }, 0);
        }
        else if (tabState.tabName === APN_TAB) {
            setTimeout(function () {
                setAssesementAPNSearch(true);
                setPolicyAPNSearch(false);
            }, 0);
        }
    }, []);

    useEffect(() => {
        if (apnListState
            && apnListState.addresses
            && apnListState.addresses.match_code !== "") {
            setLoading(false)
            setMatchCode(apnListState.addresses.match_code);
        }
        // else {
        //     setMatchCode("");
        // }

        if (apnListState.status === "failed") {
            setLoading(false);
        }
    }, [apnListState]);

    useEffect(() => {
        if (apnListState?.formFields
            && apnListState?.formFields?.APNField !== ""
            && apnListState?.formFields?.countyField?.fips !== ""
            && apnListState?.formFields?.stateField?.id !== "") {

        }
    }, [apnListState?.formFields]);

    useEffect(() => {
        if (matchCode && matchCode !== "") {
            switch (matchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setMatchText(NO_MATCH_FOUND);
                    setResultText(DEFAULT_EMPTY_TEXT);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setMatchText(NO_COVERAGE_TEXT);
                    setResultText(NO_COVERAGE_RESULT_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setMatchText(EXCEPTION_MATCH_TEXT);
                    setResultText(EXCEPTION_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.INVALID_APN.toString(): {
                    setMatchText(INVALID_APN_TEXT);
                    setResultText(INVALID_APN_RESULT_TEXT);
                    break;
                }
                default: {
                    setMatchText("");
                    setResultText("");
                }
            }
        } else {
            setMatchText("");
            setResultText("");
        }
    }, [matchCode]);

    const raiseError = (errors: any) => {
        if (errors.stateField?.id?.type === "required" &&
            errors.countyField?.fips?.type === "required" && errors.APNField?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.All_Fields_Required);
        }
        else if (errors.countyField?.fips?.type === "required" && errors.APNField?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.County_APN_Required);
        }
        else if (errors.countyField?.fips?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.County_Required);
        }
        else if (errors.APNField?.type === "required") {
            setErrorMessage(APNSearchErrorMessages.APN_Required);
        }
        else if (errors.APNField?.message === errorMessages.InvalidAPNError) {
            setErrorMessage(APNSearchErrorMessages.Invalid_APN);
        }
        else if (errors.APNField?.message === errorMessages.DoubleAstrikError) {
            setErrorMessage(APNSearchErrorMessages.Double_Astrik_Error);
        }
        else if (errors.APNField?.type === "max") {
            setErrorMessage(APNSearchErrorMessages.Maximum_APN_Error);
        }
        else {
            setErrorMessage("");
        }
    }

    const handleAssesementAPNSearchClick = () => {
        setPolicyAPNSearch(false)
        setAssesementAPNSearch(true)
    }

    const handlePolicyAPNSearchClick = () => {
        setAssesementAPNSearch(false)
        setPolicyAPNSearch(true)
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <Box className={styles.boxLayout}>
                <TabPanel value="apnTab">
                    <>
                        <Button sx={{ borderTopRightRadius: "0px", borderBottomRightRadius: "0px", backgroundColor: "white" }}
                            className={isAssesementAPNSearch ? styles.activeButton : styles.inactiveButton}
                            onClick={handleAssesementAPNSearchClick}
                            type="button" size="large" variant={isAssesementAPNSearch ? "contained" : "outlined"}>
                            <Typography textTransform={'uppercase'}
                                className={isAssesementAPNSearch ? styles.activeFontColor : styles.inactiveFontcolor}>
                                Assessment APN Search</Typography>
                        </Button>
                        <Button sx={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0px", backgroundColor: "white" }}
                            className={isPolicyAPNSearch ? styles.activeButton : styles.inactiveButton}
                            onClick={handlePolicyAPNSearchClick}
                            type="button" size="large" variant={isPolicyAPNSearch ? "contained" : "outlined"}>
                            <Typography textTransform={'uppercase'}
                                className={isPolicyAPNSearch ? styles.activeFontColor : styles.inactiveFontcolor}>
                                Policy APN Search</Typography>
                        </Button>
                        {/* <TabHeader headerText={"APN Search"} /> */}
                        <Box sx={{ marginTop: '20px', marginBottom: '15px' }}>
                            <ValidationText>Enter Parcel Number With or Without Formatting (337-104-13 OR 33710413) </ValidationText>
                            <ValidationText>For Wild Card Search, use an asterisk (*). Wild Card Search is Right Justified </ValidationText>
                            <ValidationText sx={{ fontWeight: 600 }}>*State, County and APN Required </ValidationText>
                        </Box>
                        {
                            isAssesementAPNSearch
                            &&
                            <>
                                <SearchForm onSubmit={handleSubmit} onClear={handleClearSearch} raiseError={raiseError} formFields={apnListState?.formFields} />
                                {
                                    errorMessage
                                    &&
                                    <ErrorMessage errorMessageText={errorMessage} />
                                }
                                {
                                    matchCode
                                    &&
                                    <MatchStatus matchCode={matchCode} matchText={matchText} resultText={resultText} />
                                }
                                {
                                    apnListState
                                    && apnListState.addresses
                                    && apnListState.addresses?.properties?.length > 0
                                    &&
                                    <>
                                        <Box sx={{ mt: '15px', width: '100%', overflow: 'hidden' }}>
                                            <PropertyTable
                                                tabName={APN_TAB}
                                                loggingEventName={APNSearch_Logging_Event.Search_Event}
                                                tabHeader={APN_TAB_HEADER}
                                                totalRowCount={apnListState.addresses.total_count}
                                                propertyData={apnListState.addresses.properties} />
                                        </Box>
                                    </>
                                }
                            </>
                        }
                        {
                            isPolicyAPNSearch
                            &&
                            <>
                                <PolicyAPNsearch />
                            </>
                        }
                    </>
                </TabPanel >
            </Box >
        </>
    )
}

export default APNsearch;