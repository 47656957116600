
import { axiosSecuredInstance } from '../../configuration/axiosConfig';
import { APNSearchForm } from '../../entities/Types';
import { APNSearchRequest } from '../../entities/ApiModel/APNSearchRequest';
import { APNSearchResponse } from './apnSearchSlice';
import logAPI from '../../common/Api/logAPI';
import { LogAPISearchResult } from '../../utils/common/commonUtils';
import { APNSearch_Logging_Event } from '../../utils/constants/constants';

export const fetchAddressByAPN = async (apnRequest: APNSearchForm) => {
    if (!apnRequest || !apnRequest.stateField.id || !apnRequest.countyField.fips || !apnRequest.APNField) {
        return;
    }
    let requestData: APNSearchRequest = {
        state: apnRequest.stateField.id,
        fips: apnRequest.countyField.fips,
        county: apnRequest.countyField.county,
        apn: apnRequest.APNField.replace(/[^a-zA-Z0-9*]/g, ""),
    }
    let config = {
        method: 'post',
        url: 'api/v1/apn/search',
        data: JSON.stringify(requestData)
    };
    const response: APNSearchResponse = (await axiosSecuredInstance.request(config)).data.data;
    logAPI({
        Event: APNSearch_Logging_Event.Search_Event,//"APNSearch",
        state: apnRequest?.stateField.id,
        fips: apnRequest?.countyField.fips,
        county: apnRequest?.countyField.county,
        apn: apnRequest?.APNField,
        SearchResult: LogAPISearchResult(response?.match_code)
    });
    return response;
};