import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { RootState } from '../../app/store';
import { fetchByAssessmentSearch } from './AssessmentSearchAPI';
// import { LegalSearchForm } from '../../entities/Types';
import { LegalSearchRequest } from '../../entities/ApiModel/LegalSearchRequest';
import { LegalSearchFormType } from '../../entities/Types/LegalSearchForm';
import { RootState } from '../../app/store';
import { AssesementSearchFormType } from '../../entities/Types/AssesementSearchFormType';
import { AssessmentSearchRequest } from '../../entities/ApiModel/AssessmentSearchRequest';

export interface AssessmentSearchResponse {
    match_code: string,
    message: string,
    total_count: number,
    isExecuting: boolean,
    AssessmentSearch: Record<number, any[]>;
}

export interface AssessmentSearchState {
    property: AssessmentSearchResponse;
    formFields: any;
    status: 'idle' | 'loading' | 'failed' | 'reset';
}
export const resetAssessmentSearchState = createAction('REVERT_POLICY_ASSESSMENT_SEARCH_STATE');

const initialState: AssessmentSearchState = {
    property: {
        match_code: "",
        message: "",
        total_count: 0,
        isExecuting: false,
        AssessmentSearch: {},
    },
    formFields: {},
    status: 'idle',
};

export const searchByAssessmentSearch = createAsyncThunk(
    'LegalSearch/getPropertyByAssessmentSearch',
    async (request: AssessmentSearchRequest) => {
        const response = await fetchByAssessmentSearch(request);
        return response;
    }
);

export const setAssessmentSearchToState = createAsyncThunk(
    'LegalSearch/setAssessmentSearchToState',
    async (formData: AssesementSearchFormType) => {
        return formData;
    }
);

export const getAssessmentSearchSlice = createSlice({
    name: 'AssessmentSearch',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByAssessmentSearch.pending, (state) => {
                state.status = 'loading';
                state.property.isExecuting = true;
            })
            .addCase(searchByAssessmentSearch.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    const pageNo = state.formFields.pageNo || 1;
                    // state.property.LegalSearch = action.payload.policy_search;
                    state.property.match_code = action.payload.match_code;
                    state.property.message = action.payload.message;

                    state.property.total_count = action.payload.total_count;
                    state.property.AssessmentSearch[pageNo] = action.payload.assessment_search;
                    state.property.isExecuting = false;
                }
            })
            .addCase(searchByAssessmentSearch.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setAssessmentSearchToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = action.payload
            })
            .addCase(resetAssessmentSearchState, () => initialState);
    },
});

export const AssessmentSearchData = (state: RootState) => state.rootReducer.AssessmentSearchList;
export default getAssessmentSearchSlice.reducer;
