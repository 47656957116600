
import logAPI from '../../common/Api/logAPI';
import { axiosSecuredInstance } from '../../configuration/axiosConfig';
import { AddressSearchRequest } from '../../entities/ApiModel';
import { SearchLogResult } from '../../utils/common/commonUtils';
import { AddressSearch_Logging_Event } from '../../utils/constants/constants';
import { AddressSearchResponse } from './addressSearchSlice';

export const fetchAddress = async (addressRequest: AddressSearchRequest) => {
    if (!addressRequest) {
        return;
    }

    let config = {
        method: 'post',
        url: 'api/v1/address/search',
        data: JSON.stringify(addressRequest)
    };
    try {
        const response: AddressSearchResponse = (await axiosSecuredInstance.request(config)).data.data;
        logAPI({
            Event: AddressSearch_Logging_Event.Search_Event,//"AddressSearch",
            FullStreetAddress: addressRequest?.address,
            city: addressRequest?.city,
            state: addressRequest?.state,
            zip: addressRequest?.zip,
            UnitNumber: addressRequest?.unit_number,
            SearchResult: SearchLogResult(response?.match_code)
        });
        return response;
    } catch (e: any) {
        throw new Error(e.message);
    }
};