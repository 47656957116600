import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { APNSearchForm } from '../../entities/Types';
import { fetchAddressByAPN } from './policyAPNSearchAPI';
import { PolicyAPNSearchRequest } from '../../entities/ApiModel/PolicyAPNSearchRequest';

export interface APNSearchResponse {
    match_code: string,
    message: string,
    total_count: number,
    isExecuting: boolean,
    properties: Record<number, any[]>;
}

export interface APNState {
    addresses: APNSearchResponse;
    status: 'idle' | 'loading' | 'failed' | 'reset';
    formFields: APNSearchForm
}

export const resetPolicyAPNState = createAction('REVERT_POLICY_APN_STATE');

const initialState: APNState = {
    addresses: {
        match_code: "",
        message: "",
        total_count: 0,
        isExecuting: false,
        properties: {},
    },
    formFields: {
        stateField: { id: "", label: "" },
        countyField: { fips: "", county: "" },
        APNField: "",
        pageNo: 0
    },
    status: 'idle',
};

export const searchByPolicyAPN = createAsyncThunk(
    'PolicyAPN/search',
    async (formData: PolicyAPNSearchRequest) => {
        const response = await fetchAddressByAPN(formData);
        return response;
    }
);

export const setPolicyAPNToState = createAsyncThunk(
    'PolicyAPN/setPolicyAPNToState',
    async (formData: APNSearchForm) => {
        return formData;
    }
);

export const policyApnSearchSlice = createSlice({
    name: 'PolicyAPN',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchByPolicyAPN.pending, (state) => {
                state.status = 'loading';
                state.addresses.isExecuting = true;
            })
            .addCase(searchByPolicyAPN.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload) {
                    const pageNo = state.formFields.pageNo || 1;
                    //console.log(state.formFields.pageNo);
                    state.addresses.match_code = action.payload.match_code;
                    state.addresses.message = action.payload.message;
                    state.addresses.total_count = action.payload.total_count;
                    state.addresses.properties[pageNo] = action.payload.policy_apn_search; // Storing results keyed by pageNo
                    state.addresses.isExecuting = false;
                    // state.property.policySearch = [...state.property.policySearch, ...action.payload.policy_search];
                }
            })
            .addCase(searchByPolicyAPN.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(setPolicyAPNToState.fulfilled, (state, action) => {
                state.status = 'idle';
                state.formFields = {
                    stateField: action.payload.stateField,
                    countyField: action.payload.countyField,
                    APNField: action.payload.APNField,
                    isWildCardSearch: action.payload.isWildCardSearch,
                    pageNo: action.payload.pageNo
                }
            })
            .addCase(resetPolicyAPNState, () => initialState);
    },
});

export const policyAPNList = (state: RootState) => state.rootReducer.PolicyAPN;
export default policyApnSearchSlice.reducer;
