import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { FeatureFlagData, getFeatureFlagList } from "../common/Slice/featureFlagSlice";

function useFeatureFlag(flagKey: string) {
    const featureflag = useAppSelector(FeatureFlagData);
    const dispatch = useAppDispatch();
    useMemo(async () => {
        if (featureflag && featureflag.featureflag.data.match_code === "2") {
            return;
        }
        if (!featureflag || !featureflag.featureflag.data.match_code) {
            try {
                dispatch(getFeatureFlagList())
                return true;
            } catch (error) { }
        }
    }, []);
}


export default useFeatureFlag; 