
import { axiosSecuredInstance } from '../../configuration/axiosConfig';
import { APNSearchForm } from '../../entities/Types';
import { APNSearchRequest } from '../../entities/ApiModel/APNSearchRequest';
import logAPI from '../../common/Api/logAPI';
import { LogAPISearchResult } from '../../utils/common/commonUtils';
import { APNSearchResponse } from './policyAPNSearchSlice';
import { PolicyAPNSearchRequest } from '../../entities/ApiModel/PolicyAPNSearchRequest';
import { PolicyAPNSearch_Logging_Event } from '../../utils/constants/constants';

export const fetchAddressByAPN = async (apnRequest: PolicyAPNSearchRequest) => {
    if (!apnRequest || !apnRequest.state || !apnRequest.county || !apnRequest.apn) {
        return;
    }
    let config = {
        method: 'post',
        url: 'api/v1/policyapn/search',
        data: JSON.stringify(apnRequest)
    };
    const response: any = (await axiosSecuredInstance.request(config)).data.data;
    logAPI({
        Event: PolicyAPNSearch_Logging_Event.Search_Event,
        state: apnRequest.state,
        county: apnRequest?.county,
        fips: apnRequest?.fips,
        apn: apnRequest?.apn,
        SearchResult: LogAPISearchResult(response?.match_code)
    });
    return response;
};