
import logAPI from '../../common/Api/logAPI';
import { axiosSecuredInstance } from '../../configuration/axiosConfig';
import { AddressSearchRequest } from '../../entities/ApiModel';
import { PolicyAddressSearchRequest } from '../../entities/ApiModel/PolicyAddressSearchRequest';
import { SearchLogResult } from '../../utils/common/commonUtils';
import { PolicyAddressSearch_Logging_Event } from '../../utils/constants/constants';
import { AddressSearchResponse } from './policyAddressSearchSlice';

export const fetchAddress = async (addressRequest: PolicyAddressSearchRequest) => {
    if (!addressRequest) {
        return;
    }

    let config = {
        method: 'post',
        url: 'api/v1/policyaddress/search',
        data: JSON.stringify(addressRequest)
    };
    try {
        const response: any = (await axiosSecuredInstance.request(config)).data.data;
        logAPI({
            Event: PolicyAddressSearch_Logging_Event.Search_Event,
            FullStreetAddress: addressRequest?.property_full_street_address,
            City: addressRequest?.property_city,
            State: addressRequest?.property_state_code,
            Zip: addressRequest?.property_zipcode,
            UnitNumber: addressRequest?.property_unit_number,
            SearchResult: SearchLogResult(response?.match_code)
        });
        return response;
    } catch (e: any) {
        throw new Error(e.message);
    }
};