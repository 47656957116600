import logAPI from "../../common/Api/logAPI";
import { axiosSecuredInstance } from "../../configuration/axiosConfig";
import { AssessmentSearchRequest } from "../../entities/ApiModel/AssessmentSearchRequest";
import { LogAPISearchResult } from "../../utils/common/commonUtils";
import { AssessmentLegalSearch_Logging_Event, PolicyLegalSearch_Logging_Event } from "../../utils/constants/constants";
// import logAPI from "../../common/Api/logAPI";
// import { axiosSecuredInstance } from "../../configuration/axiosConfig";
// import { SearchLogResult } from "../../utils/common/commonUtils";
// import { PolicySearch_Loggin_Event } from "../../utils/constants/constants";

export const fetchByAssessmentSearch = async (request: AssessmentSearchRequest) => {
    // if (!request) {
    //     return;
    // }
    let config = {
        method: 'post',
        url: 'api/v1/assessmentlegal/search',
        data: JSON.stringify(request)
    };
    const response: any = (await axiosSecuredInstance.request(config)).data.data;
    logAPI({
        Event: AssessmentLegalSearch_Logging_Event.Assessment_Search_Event,
        UserInput: request,
        SearchResult: LogAPISearchResult(response?.match_code),
        State: request.state,
        Fips: request.fips,
        County: request.county
    });
    return response;
};