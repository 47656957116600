import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchAddress } from './policyAddressSearchAPI';
import { AddressSearchForm } from '../../entities/Types';
import { AddressSearchRequest } from '../../entities/ApiModel';
import { PolicyAddressSearchRequest } from '../../entities/ApiModel/PolicyAddressSearchRequest';

export interface AddressSearchResponse {
  // match_code: string,
  // message: string,
  // properties: any[];
  // formFields: any;
  // total_count: number;

  match_code: string,
  message: string,
  total_count: number,
  isExecuting: boolean,
  properties: Record<number, any[]>;
}

export interface AddressState {
  addresses: AddressSearchResponse;
  status: 'idle' | 'loading' | 'failed' | 'reset';
  formFields: AddressSearchForm
}
export const resetPolicyAddressState = createAction('REVERT_POLICY_ADDRESS_STATE');

const initialState: AddressState = {
  addresses: {
    match_code: "",
    message: "",
    total_count: 0,
    isExecuting: false,
    properties: {},
  },
  formFields: {
    propertyStreetAddress: "",
    propertyUnitNumber: "",
    propertyCity: "",
    propertyState: "",
    propertyZip: "",
    pageNo: 0
  },
  status: 'idle',
};

export const searchByPolicyAddress = createAsyncThunk(
  'policyAddress/search',
  async (addressRequest: PolicyAddressSearchRequest) => {
    const response = await fetchAddress(addressRequest);
    return response;
  }
);

export const setPolicyAddressToState = createAsyncThunk(
  'policyAddress/setPolicyAddressToState',
  async (formData: AddressSearchForm) => {
    return formData;
  }
);

export const policyAddressSearchSlice = createSlice({
  name: 'policyAddress',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchByPolicyAddress.pending, (state) => {
        state.status = 'loading';
        state.addresses.isExecuting = true;
      })
      .addCase(searchByPolicyAddress.fulfilled, (state, action) => {

        state.status = 'idle';
        if (action.payload) {
          const pageNo = state.formFields.pageNo || 1;
          //console.log(state.formFields.pageNo);
          state.addresses.match_code = action.payload.match_code;
          state.addresses.message = action.payload.message;
          state.addresses.total_count = action.payload.total_count;
          state.addresses.properties[pageNo] = action.payload.policy_search; // Storing results keyed by pageNo
          state.addresses.isExecuting = false;
          // state.property.policySearch = [...state.property.policySearch, ...action.payload.policy_search];
        }


        // state.status = 'idle';
        // if (!action.payload)
        //   return;
        // state.addresses.properties = action.payload.properties;
        // state.addresses.match_code = action.payload.match_code;
        // state.addresses.message = action.payload.message;
        // state.addresses.total_count = action.payload.total_count ?? 0;
      })
      .addCase(searchByPolicyAddress.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(setPolicyAddressToState.fulfilled, (state, action) => {
        state.status = 'idle';
        state.formFields = {
          propertyStreetAddress: action.payload.propertyStreetAddress,
          propertyUnitNumber: action.payload.propertyUnitNumber,
          propertyCity: action.payload.propertyCity,
          propertyState: action.payload.propertyState,
          propertyZip: action.payload.propertyZip,
          pageNo: action.payload.pageNo
        }
      })
      .addCase(resetPolicyAddressState, () => initialState);
  },
});

export const policyAddressList = (state: RootState) => state.rootReducer.policyAddress;
export default policyAddressSearchSlice.reducer;
